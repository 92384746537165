<template>
  <div 
    :class="['carousel', (this.carouselItems) ? 'carousel-2 pl-4 pl-sm-5' : 'carousel-1 w-100']"
    ref="wrapper"
    >
    <VueSlickCarousel 
      v-if="carouselItems"
      ref="carousel"
      :arrows="true" 
      :dots="false"
      :speed="300"
      :slidesToShow="1"
      :slidesToScroll="2"
      :variableWidth="true"
      :lazyLoad="'progressive'"
      :infinite="false"
      :draggable="true"
      :responsive="responsiveOptions"
    > 
      <div 
        v-for="experience in carouselItems"
        :key="experience.id"
        :id="experience.id" 
        :class="classes"
        >
        <div class="card-image">
          <a 
            :href="`/experiences/${ experience.id }`" 
            :title="experience.title"
          >
            <img 
              v-if="!!experience.images"
              :src="experience.images[0].url"
              :alt="experience.title"
              class="p-3"
            >
            <div
              v-else
              class="p-3"
            >
              <span class="img-holding"></span>
            </div>
            <span 
              class="theme-name p-1" 
              :style="`background-color: ${ experience.theme.color }`"
            >
              {{ experience.theme.name }}
            </span>
          </a>
        </div>
        <div class="card-header pt-0 pb-0">
          <h2 class="mb-0">
            {{ experience.title }}
          </h2>
          <h3 
            class="mb-0" 
            :style="`color: ${ experience.theme.color }`"
          >
            {{ experience.regions }}
          </h3>
        </div>
        <div class="card-body pt-0">
          {{ experience.description }}
          <br />
          <a 
            :href="`/experiences/${ experience.id }`" 
            :style="`color: ${ experience.theme.color }`"
            :title="`Read more about ${ experience.title }`"
          >
            Read more
          </a>
        </div>
        <div class="card-footer">
          <div class="row align-items-center">
            <span class="col-8 price pl-0 pr-1" style="color: <%= experience.theme.color %>">
              {{ experience.price }}
              <br v-if="experience.groupBooking" />
              <small v-if="experience.groupBooking">
                PRIVATE GROUP PRICE
              </small>
            </span>
            <span class="col-4 p-0">
              <a 
                :href="`/experiences/${ experience.id }`" 
                class="btn btn-secondary pl-0 pr-0"
                :title="`More info about ${ experience.title }`"
              >
                More info
              </a>
            </span>
          </div>
        </div>
      </div>
      <template #prevArrow>
        <button type="button" class="btn-prev btn-primary"><</button>
      </template>
      <template #nextArrow>
        <button type="button" class="btn-next btn-primary">></button>
      </template>
    </VueSlickCarousel>
    <VueSlickCarousel 
      v-else-if="count > 1"
      ref="carousel"
      :arrows="true" 
      :speed="300"
      :slidesToShow="1"
      :slidesToScroll="1"
      :asNavFor="$refs.carouselNav"
    > 
      <div 
        v-for="index in (youtubeEmbedURL) ? count-1 : count"
        :key="index"
      >
        <div class="img-holding-show" v-if="!images" ></div>
        <img v-else :src="images[index-1].url" :class="`w-100`">
      </div>
      <iframe :height="youtubeHeight" :src="youtubeEmbedURL" v-if="youtubeEmbedURL"></iframe>
      <template #prevArrow>
        <button type="button" class="btn-prev btn-primary"><</button>
      </template>
      <template #nextArrow>
        <button type="button" class="btn-next btn-primary">></button>
      </template>
    </VueSlickCarousel>
    <div v-else >
      <div class="img-holding-show" v-if="!images" ></div>
      <img 
        v-else
        :src="images[0].url" 
        :class="`w-100`"
      >
    </div>
     <VueSlickCarousel 
      v-if="showNav"
      ref="carouselNav"
      :slidesToShow="4"
      :asNavFor="$refs.carousel"
      :focusOnSelect="true"
    > 
      <div 
        v-for="index in (youtubeEmbedURL) ? count-1 : count"
        :key="index"
      >
        <div class="img-holding-show" v-if="!images"></div>
        <img v-else :src="images[index-1].url" :class="`w-100 p-2`">
      </div>
      <img width="196" height="151" class="w-100 p-2" :src="youtubeImageURL" v-if="youtubeImageURL"/>
      <template #prevArrow>
        <button type="button" class="btn-prev btn-primary"><</button>
      </template>
      <template #nextArrow>
        <button type="button" class="btn-next btn-primary">></button>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import axios from "axios";
  let AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
  axios.defaults.headers.common['X-CSRF-Token'] = AUTH_TOKEN
  axios.defaults.headers.common['Accept'] = 'application/json'

  export default {
    name: 'carousel',
    props: {
      experiences: Array,
      experienceid: Number,
      youtubeid: String,
      count: Number,
      classes: String,
      options: {
        type: Object,
        default: () => ({
          arrows: true,
          dots: false,
          cards: true,
          width: 300,
          height: 230
        })
      }
    },
    components: {
      VueSlickCarousel
    },
    data() {
      return {
        carouselItems: this.experiences,
        showNav: this.$attrs.withnav,
        youtubeEmbedURL: null,
        youtubeImageURL: null,
        youtubeHeight: (this.options.vidHeight) ? this.options.vidHeight : null,
        images: null,
        wrapperElm: null,
        infinite: false,
        responsiveOptions: [{
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              dots: false
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 576,
            settings: {
              infinite: true,
              lazyLoad: "ondemand",
              slidesToShow: (this.elmCount > 1) ? 2 : 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    },
    computed: {
      elmCount: function () {
        return this.experiences ? this.experiences.length : this.count
      }
    },
    mounted() {      
      this.wrapperElm = this.$refs.wrapper;
      this.youtubeEmbedURL = (this.youtubeid) ? 'https://www.youtube-nocookie.com/embed/' + this.youtubeid + '?autoplay=0&mute=1&rel=0&controls=0&modestbranding=1' : null;
      this.youtubeImageURL = (this.youtubeid) ? 'https://img.youtube.com/vi/' + this.youtubeid + '/0.jpg' : null;
      window.addEventListener('scroll', this.handleScroll);
      if (this.experienceid) {
        return axios
          .get("/experience-image?id=" + this.experienceid + "&width=" + this.options.width + "&height=" + this.options.height )
          .then(response => {
            this.images = response.data
          })
          .catch(e => {
            console.log(e);
          });
      }else{
        this.carouselItems.forEach((experience) => {
          return axios
            .get("/experience-image?id=" + experience.id + "&width=" + this.options.width + "&height=" + this.options.height )
            .then(response => {
              experience.images = response.data
            })
            .catch(e => {
              console.log(e);
            });
        })
      }
    },
    methods:{
      handleScroll() {
        let elm = this.wrapperElm
        if (window.scrollY > elm.getBoundingClientRect().top) {
          if (!elm.className.includes('scrolled')) {
            elm.classList.add('scrolled')
          }
          // refs.wrapper.classList.add('scrolled')
        }
      }
    }
  }
</script>

<style scoped>
  .carousel {
    overflow: hidden;
  }
  .img-holding {
    background-color: grey; 
    display: block; 
    width: 100%;
    max-width: 100%; 
    height: 228px
  }

  .img-holding-show {
    background-color: grey; 
    display: block; 
    width: 450px;
    max-width: 100%; 
    height: 352px
  }

  iframe {
    border: none;
    max-height: 100%;
  }
</style>