import Vue from 'vue/dist/vue.esm'

//Custom components
import Carousel from "../components/Carousel.vue"

const initCarousel = function () {
  let vueContainers = document.getElementsByClassName('vue-container-carousel');
  if (vueContainers.length > 0) {
    for (let vc of vueContainers) {
      new Vue({
        el: vc,
        components: {
          Carousel
        }
      })
    }
  }
}

document.addEventListener('DOMContentLoaded', initCarousel, false)
